import React, { useState, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import downloadIcon from "../../images/download-icon.svg";
import useAxios from "../../axiosinstance";
import { useSelector } from "react-redux";
import AuditOtp from "./AuditOtp";
import { Modal } from "react-bootstrap";
import Message from "../custom/toster/Message";
import {
  HoursMinutesFormatDataExport,
  UserDateFormat,
  HoursMinutesFormat,
} from "../../utilities/DateFormate";

export default function AuditToolData({keyID}) {
  const [showModal, setshowModal] = useState(false);
  const [validationerr, setValidationerr] = useState(false);
  const [activeloading, setactiveLoading] = useState(true);
  const axiosInstance = useAxios();
  const login = useSelector((state) => state.mainReducer.loginData.arr);
  const [clickedIndex, setClickedIndex] = useState(null);
  const [columns, setColumns] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [resId, setResId] = useState("");
  const [Otp, setOtp] = useState("");
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState();
  const [show, setShow] = useState(false);
  const pageSize = 10;
console.log(keyID,"keyIDkeyID")
  const handleOtpmodal = () => {
    setshowModal(true);
  };
  const handleClose = () => {
    setshowModal(false);
    setValidationerr(false);
  };

  const handleClick = (index) => {
    setClickedIndex(index);
  };

  const fetchMoreData = async () => {
    try {
      const response = await axiosInstance.current.get(
        `extapp/doctors/get_audit_data?ak_dc=${login.ing_ak_id}&offset=${page}&limit=${pageSize}`
      );
      const newData = response.data.result;
      setColumns((prevColumns) => [...prevColumns, ...newData]);
      if (newData.length < pageSize) {
        setHasMore(false);
      }
      setPage(page + 1);
      setactiveLoading(false);
    } catch (err) {
      setHasMore(false);
    }
  };

  useEffect(() => {
    if(keyID==1){
    fetchMoreData();
    }
  }, [keyID]);

  // useEffect(() => {
  //   if (useFunction) {
  //     handleAuditData(columns);
  //   }
  // }, [columns]);

  const downloadFile = async (url, fileName) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const handleDownload_csv = async (link) => {
    const downloadQueue = [];
    if (link[0]) {
      downloadQueue.push({ url: link[0].url, name: link[0].name });
    }
    if (link[1]) {
      downloadQueue.push({ url: link[1].url, name: link[1].name });
    }
    if (link[2]) {
      downloadQueue.push({ url: link[2].url, name: link[2].name });
    }
    for (const downloadItem of downloadQueue) {
      try {
        await downloadFile(downloadItem.url, downloadItem.name);
      } catch (error) {
        console.error("Error downloading file:", error);
      }
    }
  };

  const handleDownload = async (resid) => {
    setResId(resid);
    handleOtpmodal();
    try {
      const response = await axiosInstance.current.post(
        `extapp/doctors/sendOTPForAuditTool/${login.ing_ak_id}/${resid}`
      );
      if (response) {
        setMessage("OTP sent successfully");
        setMessageType("success");
        setShow(true);
        setTimeout(() => {
          setShow(false);
          setMessage("");
        }, 3000);
      }
    } catch (err) {
      console.error(err);
      setMessage("Something went wrong");
      setMessageType("error");
      setShow(true);
      setTimeout(() => {
        setShow(false);
        setMessage("");
      }, 3000);
    }
  };

  const handleExport = async () => {
    if (Otp.length < 6) {
      setValidationerr(true);
    } else {
      try {
        const response = await axiosInstance.current.get(
          `extapp/doctors/downloadauditexport?resId=${resId}&otp=${Otp}&ak_id=${login.ing_ak_id}`
        );
        handleDownload_csv(response.data.signedUrls);
        handleClose();
      } catch (err) {
        setMessage("Invalid OTP");
        setMessageType("error");
        setShow(true);
        setTimeout(() => {
          setShow(false);
          setMessage("");
        }, 3000);
        console.error(err);
      }
    }
  };

  return (
    <>
      {show && <Message message={message} type={messageType} />}
      <div className="tableContainer scrollbar_verts" id="rpScrollableDiv">
        <InfiniteScroll
          dataLength={columns.length}
          next={fetchMoreData}
          hasMore={hasMore}
          scrollableTarget="rpScrollableDiv"
        >
          {!activeloading && columns.length > 0 ? (
            <>
              {columns.map((column, index) => (
                <div className="box-content text-left">
                  <div className="request_view1" style={{ padding: "5px" }}>
                    <span
                      id="new-task-add"
                      key={index}
                      className={`btn_nill1 d-flex position-relative justify-content-between minheight_60${
                        clickedIndex === index ? "index_click" : "active-row"
                      }`}
                      onClick={() => handleClick(index)}
                    >
                      <div className="d-flex align-items-center ms-3">
                        <div
                          className="me-3"
                          style={{
                            fontWeight: "bold",
                            width: "500px",
                            whiteSpace: 'normal',
                             wordWrap:"break-word",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          <span className="">{column.filename}</span>
                        </div>
                        <div className="me-3" style={{  width: '200px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', }}>
                          <span>
                            {UserDateFormat(
                              column.created_at,
                              login.ing_date_formate,
                              login.ing_timezone
                            )}
                          </span>
                        </div>
                        <div className="me-3">
                          <span>
                            {HoursMinutesFormat(column.created_at)}
                          </span>
                        </div>
                      </div>

                      <div className="d-flex">
                        <div
                          className={`new__note_btn f_600 img_white c_pointer`}
                          style={{ position: "", right: "8px", top: "3px" }}
                        >
                          <div className="d-flex align-items-center">
                            {column.status === "0" ? (
                              <div
                                style={{
                                  width: "50px",
                                  height: "24px",
                                  borderRadius: "90px",
                                  backgroundColor: "#F3F5F7",
                                  marginLeft: "10px",
                                  marginRight: "15px",
                                  border: "1px solid rgb(204 194 232)",
                                }}
                              >
                                <p
                                  style={{
                                    marginLeft: "8px",
                                    marginTop: "2px",
                                  }}
                                >
                                  New
                                </p>
                                <span>
                                  <p>Processing...</p>
                                </span>
                              </div>
                            ) : (
                              <img
                                onClick={() =>
                                  handleDownload(column.resource_id)
                                }
                                src={downloadIcon}
                                alt="Download"
                                className="mt-1 c_pointer"
                                style={{ marginLeft: "8px" }}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </span>
                  </div>
                </div>
              ))}
            </>
          ) : (
            !activeloading && (
              <div colSpan="5" className="text-center no_data">
                No data found
              </div>
            )
          )}
        </InfiniteScroll>
      </div>

      <Modal
        show={showModal}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-md patient_notes_popup modal-dialog-centered"
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-center">
            Check your email for a code to export your data.
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <AuditOtp
            statusmodel={true}
            handleExport={handleExport}
            setOtp={setOtp}
            validationerr={validationerr}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}
