import React, { useEffect, useState } from "react";
import notification from "../../images/notification.png";
import downloadLink from "../../images/download-icon.svg";
import arrowRight from "../../images/arrow-bright.svg";
import arrowLeft from "../../images/back-arrow-blue.svg";
import contact from "../../images/contact.svg";
import tick from "../../images/tick.png";
import cross from "../../images/x 1.png";
import DeleteConfirmation from "../custom/delete-confirmation/DeleteConfirmation";
import { useNavigate } from "react-router-dom";
import useAxios from "../../axiosinstance";
import { useSelector, useDispatch } from "react-redux";
import LoadingSpinner from "../custom/loader/LoadingSpinner";
import { getTimeDifference,DateFormate } from "../../utilities/DateFormate";
import promsIcon from "../../images/promsIcon.png";
import consent from "../../images/consent.png";
import documentIcon from "../../images/file 2.svg";
import consentIcon from "../../images/briefcase 1.svg";
import clip from "../../images/clipboard 1.svg";
import { notificationRef } from "../../redux/actions";
import InfiniteScroll from "react-infinite-scroll-component";
import appoinment from "../../images/calendar.png"
function Notifications() {
  const login = useSelector((state) => state.mainReducer.loginData.arr);
  const axiosInstance = useAxios();
  const dispatch = useDispatch();
  const [getNotificationData, setNotificationData] = useState([]);
  const [clickedIndex, setClickedIndex] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [notiId, setNotiId] = useState("");
  const [check, setCheck] = useState("");
  const [reff, setReff] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [limit] = useState(10);
  const [offset, setOffset] = useState(10);
  const navigate = useNavigate();
  const handleClick = (index) => {
    setClickedIndex(index);
  };

  const hideConfirmationModal = () => {
    setShowModal(false);
  };

  const submitDelete = () => {
    setShowModal(false);
  };

  const handleOne = (column) => {
    setNotiId(column._id);
    setCheck(column.check);
    setShowModal(true);
  };

  const getNotification = async () => {
    try {
      const response = await axiosInstance.current.get(
        `extapp/patients/get_notifications?ak_id=${login.ing_ak_id}&check=&limit=${limit}&offset=0`
      );
      if (response.status === 200) {
        setNotificationData(response.data.data);
      }
    } catch (err) {
      setNotificationData([]);
      dispatch(notificationRef([]));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (login.ing_ak_id) {
      getNotification();
    }
  }, [login.ing_ak_id, reff]);

  const addMoreData = async () => {
    setOffset(offset + 10);
    const response = await axiosInstance.current.get(
      `extapp/patients/get_notifications?ak_id=${login.ing_ak_id}&check=&limit=${limit}&offset=${offset}`
    );
    const resdata = response.data.data;
    setTimeout(() => {
      setNotificationData(getNotificationData.concat(resdata));
    }, 500);
  };

  const fetchMoreData = () => {
    addMoreData();
  };

  let notification_data = useSelector(
    (state) => state.mainReducer.notificationRef.arr
  );

  const markAsRead = async (type) => {
    const resouceId = type.resource_id;
    let speciality = type?.notify_message?.speciality || "";
    const org = type?.notify_message?.org_id || "";
    const sender = type.sender;
    const firstName=type?.notify_message?.firstname;
    const pt_id = type?.notify_message?.pt_id || "";
    if (speciality === 24) {
      speciality = 5;
    }
    if (type.redirect_type === 6) {
      navigate(`/my-consents?consent_id=${type.notify_message.consent_id}`);
    } else if (type.redirect_type === 1) {
      // navigate(
      //   `/view-management-plan/${window.btoa(sender)}/${window.btoa(
      //     resouceId
      //   )}/?org=${window.btoa(org) || ""}&formType=${
      //     window.btoa(speciality) || ""
      //   }&view_circular=true`
      // );
      setTimeout(() => {
      navigate(`/patient-details/${window.btoa(sender)}?formid=${speciality}`);
    }, 3000);
    } else if (type.redirect_type === 12) {
      // navigate(
      //   `/view-management-plan/${window.btoa(sender)}/${window.btoa(
      //     resouceId
      //   )}/?org=${window.btoa(org) || ""}&formType=${
      //     window.btoa(speciality) || ""
      //   }&view_circular=true`
      // );
      navigate(`/patient-details/${window.btoa(sender)}?formid=${speciality}`);
     
    } else if (type.redirect_type === 13) {
      navigate(`/my-settings?id=incoming`);
    }  else if (type.redirect_type === 15) {
      navigate("/schedular");
    }else if(type.redirect_type === 14){
navigate( `/patient-view/${window.btoa(pt_id) || ""}/${window.btoa(org)}`)
    
    } 
    // else if(type.redirect_type === 10){
    //   navigate(`/doctor-score-list/${window.btoa(sender)}?name=${firstName}`);
    // }
    const json = JSON.stringify({
      _id: type._id,
      ak_id: login.ing_ak_id,
    });
    try {
      const response = await axiosInstance.current.put(
        `extapp/patients/seen_notification`,
        json,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200) {
        setReff(new Date().getMilliseconds());
        dispatch(notificationRef(notification_data - 1));
      }
      if (!type._id) {
        dispatch(notificationRef([]));
      }
    } catch (err) {}
  };
  
  let id = notiId || "";

  const deleteNotification = async () => {
    try {
      const response = await axiosInstance.current.delete(
        `extapp/patients/delete_notification?ak_id=${login.ing_ak_id}&_id=${id}`
      );
      if (response.status === 200) {
        setReff(new Date().getMilliseconds());
        if (!check === true) {
          dispatch(notificationRef(notification_data - 1));
        }
        setShowModal(false);
      }
    } catch (err) {}
  };

  return (
    <div>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="notification_container_inner">
          <div className="notification_header d-flex align-items-center">
            <div className="d-inline-block notification_title">
              <h3>Notifications</h3>
            </div>
            {getNotificationData.length !== 0 ? (
              <>
                <button
                  className="LinkBtn1"
                  style={{ width: "140px" }}
                  onClick={markAsRead}
                >
                  Mark all as read
                </button>
              </>
            ) : (
              ""
            )}
          </div>
          {getNotificationData.length !== 0 ? (
            <>
              <div class="whitebox ps-0">
                <div className="mt-4 m-lg-4">
                  <div className="tableContainer" id="rpScrollableDiv">
                    <InfiniteScroll
                      dataLength={getNotificationData.length}
                      next={fetchMoreData}
                      hasMore={true}
                      scrollableTarget="rpScrollableDiv"
                    >
                      {getNotificationData.map((column, index) => (
                        <>
                          <div
                            key={index}
                            style={{ width: "100%" }}
                            className={`notify_item1 d-flex ${
                              clickedIndex === index
                                ? "notify_msg_read"
                                : "notify_msg_read"
                            }`}
                            onClick={() => handleClick(column)}
                          >
                            <div
                              style={{
                                width: "95%",
                                backgroundColor:
                                  column.check === true ? "white" : "",
                              }}
                              className={`notify_item d-flex align-items-center  ${
                                clickedIndex === index
                                  ? "notify_msg_unread"
                                  : "notify_msg_unread"
                              }`}
                              onClick={() => markAsRead(column)}
                            >
                              <div className="d-flex align-items-center ms-3 m-lg-0">
                                <div className="me-0">
                                  {column.redirect_type === 5 && (
                                    <img
                                      src={downloadLink}
                                      alt="img"
                                      style={{ height: "20px", width: "20px" }}
                                    />
                                  )}
                                  {column.index === 2 && (
                                    <img
                                      src={contact}
                                      alt="img"
                                      style={{ height: "34%", width: "51px" }}
                                    />
                                  )}
                                  {column.redirect_type === 15 && (
                                    <img
                                      src={appoinment}
                                      alt="img"
                                       style={{
                                        height: "22%",
                                        width: "22px",
                                        marginLeft: "5px",
                                        marginRight: "10px",
                                      }}
                                    />
                                  )}
                                  {(column.redirect_type === 12 ||
                                   
                                    column.redirect_type === 1 || column.redirect_type === 10) && (
                                    <img
                                      src={clip}
                                      alt="img"
                                      style={{
                                        height: "22%",
                                        width: "22px",
                                        marginLeft: "5px",
                                        marginRight: "10px",
                                      }}
                                    />
                                  )}
                                  {(column.redirect_type === 11 ||
                                    column.redirect_type === 13 ||
                                    column.redirect_type === 14) && (
                                    <img
                                      src={documentIcon}
                                      alt="img"
                                      style={{
                                        height: "22%",
                                        width: "22px",
                                        marginLeft: "5px",
                                        marginRight: "10px",
                                      }}
                                    />
                                  )}
                                  {column.redirect_type === 6 && (
                                    <img
                                      src={consentIcon}
                                      alt="img"
                                      style={{
                                        height: "22%",
                                        width: "22px",
                                        marginLeft: "5px",
                                        marginRight: "10px",
                                      }}
                                    />
                                  )}
                                  {/* {column.redirect_type === 1 && (
                                <img
                                  src={clip}
                                  alt="img"
                                  style={{
                                    height: "22%",
                                    width: "22px",
                                    marginLeft: "5px",
                                    marginRight: "10px",
                                  }}
                                />
                              )} */}
                                </div>
                                <div className="me-3">
                                  {column.redirect_type === 6 && (
                                    <>
                                      <strong
                                        className="notify_msg d-inline"
                                        style={{
                                          marginLeft: 10,
                                        }}
                                      >
                                        {column.notify_message.firstname +
                                          " " +
                                          column.notify_message.lastname}
                                      </strong>
                                      <strong
                                        style={{
                                          fontWeight: 500,
                                          marginLeft: 7,
                                          color: "#3f2783",
                                        }}
                                      >
                                        has{" "}
                                        {column.notify_message.status === 1
                                          ? "signed"
                                          : column.notify_message.status === -1
                                          ? "rejected"
                                          : column.notify_message.status === 3
                                          ? "expired"
                                          : ""}{" "}
                                        consent form
                                      </strong>
                                    </>
                                  )}
                                  {column.redirect_type === 10 && (
                                    <>
                                      <strong
                                        className="notify_msg d-inline"
                                        style={{
                                          marginLeft: 10,
                                        }}
                                      >
                                        {column.notify_message.firstname +
                                          " " +
                                          column.notify_message.lastname}
                                      </strong>
                                      <strong
                                        style={{
                                          fontWeight: 500,
                                          marginLeft: 7,
                                          color: "#3f2783",
                                        }}
                                      >
                                       did not complete the requested {""}
                                       {column.notify_message.speciality === 22
                                          ? "Elbow"
                                          : column.notify_message.speciality ===
                                            4
                                          ? "Shoulder"
                                          : column.notify_message.speciality ===
                                            6
                                          ? "Hip"
                                          : column.notify_message.speciality ===
                                            21
                                          ? "Foot and Ankle"
                                          : column.notify_message.speciality ===
                                            5
                                          ? "Adolescent knee"
                                          : column.notify_message.speciality ===
                                            20
                                          ? "Pediatric Knee"
                                          : column.notify_message.speciality ===
                                            24
                                          ? "Adult Knee"
                                          : column.notify_message.speciality ===
                                            23
                                          ? "Hand and Wrist"
                                          : column.notify_message.speciality ===
                                            33
                                          ? "General"
                                          : ""}{" "}
                                        PROMs {" "}
                                      </strong>
                                    </>
                                  )}
                                  {column.redirect_type === 11 && (
                                    <>
                                      <strong
                                        className="notify_msg d-inline"
                                        style={{
                                          marginLeft: 10,
                                        }}
                                      >
                                        {column.notify_message.firstname +
                                          " " +
                                          column.notify_message.lastname}
                                      </strong>
                                      <strong
                                        style={{
                                          fontWeight: 500,
                                          marginLeft: 7,
                                          color: "#3f2783",
                                        }}
                                      >
                                        has{" "}
                                        {column.notify_message.accept === 1
                                          ? "accepted"
                                          : column.notify_message.accept === -1
                                          ? "rejected"
                                          : ""}{" "}
                                        Auto Sharing
                                      </strong>
                                    </>
                                  )}
                                  {column.redirect_type === 12 && (
                                    <>
                                      <strong
                                        style={{
                                          fontWeight: 500,
                                          marginLeft: 7,
                                          color: "#3f2783",
                                        }}
                                      >
                                        {column.notify_message.speciality === 22
                                          ? "Elbow"
                                          : column.notify_message.speciality ===
                                            4
                                          ? "Shoulder"
                                          : column.notify_message.speciality ===
                                            6
                                          ? "Hip"
                                          : column.notify_message.speciality ===
                                            21
                                          ? "Foot and Ankle"
                                          : column.notify_message.speciality ===
                                            5
                                          ? "Adolescent knee"
                                          : column.notify_message.speciality ===
                                            20
                                          ? "Pediatric Knee"
                                          : column.notify_message.speciality ===
                                            24
                                          ? "Adult Knee"
                                          : column.notify_message.speciality ===
                                            23
                                          ? "Hand and Wrist"
                                          : column.notify_message.speciality ===
                                            33
                                          ? "General"
                                          : ""}{" "}
                                        PROMs from{" "}
                                      </strong>
                                      <strong className="notify_msg d-inline">
                                        {column.notify_message.firstname +
                                          " " +
                                          column.notify_message.lastname}
                                      </strong>
                                      <strong
                                        style={{
                                          fontWeight: 500,
                                          marginLeft: 3,
                                          color: "#3f2783",
                                        }}
                                      >
                                        has
                                      </strong>
                                      <strong
                                        style={{
                                          fontWeight: 500,
                                          marginLeft: 5,
                                          color: "#3f2783",
                                        }}
                                      >
                                        been shared with you
                                      </strong>
                                    </>
                                  )}
                                  {column.redirect_type === 13 && (
                                    <>
                                      <strong
                                        className="notify_msg d-inline"
                                        style={{
                                          marginLeft: "10px",
                                        }}
                                      >
                                        {column.notify_message.firstname +
                                          " " +
                                          column.notify_message.lastname}
                                      </strong>

                                      <strong
                                        style={{
                                          fontWeight: 500,
                                          marginLeft: 3,
                                          color: "#3f2783",
                                        }}
                                      >
                                        has
                                      </strong>
                                      <strong
                                        style={{
                                          fontWeight: 500,
                                          marginLeft: 5,
                                          color: "#3f2783",
                                        }}
                                      >
                                        {column.notify_message.resend === true
                                          ? "re-sent Auto Sharing"
                                          : "requested Auto Sharing"}
                                      </strong>
                                    </>
                                  )}
                                  {column.redirect_type === 14 && (
                                    <>
                                      <strong
                                        className="notify_msg d-inline"
                                        style={{
                                          marginLeft: "10px",
                                        }}
                                      >
                                        {column.notify_message.firstname +
                                          " " +
                                          column.notify_message.lastname}
                                      </strong>

                                      <strong
                                        style={{
                                          fontWeight: 500,
                                          marginLeft: 3,
                                          color: "#3f2783",
                                        }}
                                      >
                                       has shared a patient resource with you
                                      </strong>
                                    </>
                                  )}
                                  {column.redirect_type === 15 && (
                                    <>
                                      <strong
                                        style={{
                                          fontWeight: 500,
                                          marginLeft: 10,
                                          color: "#3f2783",
                                        }}
                                      >
                                        You have a
                                      </strong>
                                      <strong
                                        className="notify_msg d-inline"
                                        style={{
                                          marginLeft: 3,
                                        }}
                                      >
                                        {column.notify_message.schedule_type}{""}
                                      </strong>
                                      <strong
                                        style={{
                                          fontWeight: 500,
                                          marginLeft: 3,
                                          color: "#3f2783",
                                        }}
                                      >
                                           on {" "} {DateFormate(column.notify_message.date)}
                                      </strong>
                                   
                                    </>
                                  )}
                                  {column.redirect_type === 1 && (
                                    <>
                                      <strong
                                        className="notify_msg d-inline"
                                        style={{
                                          marginLeft: "10px",
                                        }}
                                      >
                                        {column.notify_message.firstname +
                                          " " +
                                          column.notify_message.lastname}
                                      </strong>
                                      <strong
                                        style={{
                                          fontWeight: 500,
                                          marginLeft: 7,
                                          color: "#3f2783",
                                        }}
                                      >
                                        has completed{" "}
                                        {column.notify_message.speciality === 22
                                          ? "Elbow"
                                          : column.notify_message.speciality ===
                                            4
                                          ? "Shoulder"
                                          : column.notify_message.speciality ===
                                            6
                                          ? "Hip"
                                          : column.notify_message.speciality ===
                                            21
                                          ? "Foot and Ankle"
                                          : column.notify_message.speciality ===
                                            5
                                          ? "Adolescent knee"
                                          : column.notify_message.speciality ===
                                            20
                                          ? "Paediatric Knee"
                                          : column.notify_message.speciality ===
                                            24
                                          ? "Adult Knee"
                                          : column.notify_message.speciality ===
                                            23
                                          ? "Hand and Wrist"
                                          : column.notify_message.speciality ===
                                            33
                                          ? "General"
                                          : ""}{" "}
                                        PROMs
                                      </strong>
                                    </>
                                  )}

                                  <div className="notification_type">
                                    <div className="notify_msg">
                                      <strong
                                        style={{
                                          fontWeight: 500,
                                          marginLeft: 10,
                                          color: "#3f2783",
                                        }}
                                      >
                                        {getTimeDifference(column.created_at)}
                                      </strong>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="right_icon d-flex">
                                <img
                                  src={arrowRight}
                                  alt="img"
                                  className="d-flex notify_item_right"
                                  onClick={() => markAsRead(column)}
                                />
                              </div>
                            </div>

                            <img
                              src={cross}
                              alt="img"
                              className="cross_icon d-flex"
                              style={{ marginLeft: "auto", marginRight: 10 }}
                              onClick={() => handleOne(column)}
                            />
                          </div>
                        </>
                      ))}
                    </InfiniteScroll>
                  </div>
                </div>
                <div className="d-flex justify-content-center align-items-center">
                  <button
                    className="LinkBtn1"
                    style={{ alignContent: "center" }}
                    onClick={() => handleOne("")}
                  >
                    Delete All
                  </button>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="panel-body p_25">
                <div className="no_data">No Notification found</div>
              </div>
            </>
          )}
        </div>
      )}
      <DeleteConfirmation
        showModal={showModal}
        confirmModal={deleteNotification}
        hideModal={hideConfirmationModal}
      />
    </div>
  );
}

export default Notifications;
